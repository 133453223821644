import { useElements, useStripe } from '@stripe/react-stripe-js';
import { message } from 'antd';
import { useContext, useEffect, useMemo } from 'react';

import CardVCC from 'app/components/commons/Cards/CardVCC';
import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { useGetEphemeralKey } from 'app/hooks/useStripe/useStripe';
import { BookingDetails } from 'app/typings/extranetBookings/booking';
import { VccStatus } from 'app/typings/vcc';
import {
  getSumOfIssuingRefunds,
  getTotalRefundsExcludingIssuings,
  getVCCStatus,
} from 'app/utils/vcc';

import './VirtualCard.scss';
import CardAlerts from './_components/CardAlerts';
import CardDetails from './_components/CardDetail';
import CardHistory from './_components/CardHistory';
import CardRefunds from './_components/CardRefund';
import CardStatus from './_components/CardStatus';

type VirtualCardProps = {
  booking: BookingDetails;
  hotelId: number;
};

const VirtualCard = ({ booking, hotelId }: VirtualCardProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const status = getVCCStatus(booking, currencyFormatter);
  const cardIsCancelled = status.status === VccStatus.Cancelled;
  const cardIsDeactivated = status.status === VccStatus.Deactivated;
  const cardIsBlur = cardIsCancelled || cardIsDeactivated;

  const stripe = useStripe();
  const elements = useElements();

  const { getEphemeralKey } = useGetEphemeralKey(hotelId, booking.code);

  const baseStyle = useMemo(
    () => ({
      color: '#fff',
      fontFamily: 'system-ui, "Helvetica Neue", sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
    }),
    []
  );

  const alertCopyButton = () => {
    message.success('Copy to clipboard!');
  };

  useEffect(() => {
    async function inner() {
      if (!stripe || !elements || !booking.issuingCardId) {
        return null;
      }

      const nonceResult = await stripe.createEphemeralKeyNonce({
        issuingCard: booking.issuingCardId,
      });
      const nonce = nonceResult.nonce ?? '';

      const ephemeralKey = (await getEphemeralKey(nonce, booking.issuingCardId))
        .secret;

      const cardElement = elements?.create('issuingCardNumberDisplay', {
        issuingCard: booking.issuingCardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKey,
        style: {
          base: {
            ...baseStyle,
            fontSize: '18px',
            fontWeight: 700,
          },
        },
      });

      const cardCvc = elements?.create('issuingCardCvcDisplay', {
        issuingCard: booking.issuingCardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKey,
        style: {
          base: baseStyle,
        },
      });

      const cardExpiry = elements?.create('issuingCardExpiryDisplay', {
        issuingCard: booking.issuingCardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKey,
        style: {
          base: baseStyle,
        },
      });

      const cardCopyButton = elements.create('issuingCardCopyButton', {
        toCopy: 'number',
        style: {
          base: { ...baseStyle, padding: '7px' },
        },
      });

      const cardPin = elements?.create('issuingCardPinDisplay', {
        issuingCard: booking.issuingCardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKey,
        style: {
          base: {
            ...baseStyle,
            color: '#262626',
          },
        },
      });

      cardCvc.mount('#card-cvc');
      cardElement.mount('#card-number');
      cardExpiry.mount('#card-expiry');
      cardPin.mount('#card-pin');
      cardCopyButton.mount('#card-number-copy');

      cardCopyButton.on('click', () => {
        alertCopyButton();
      });
    }

    inner();
  }, [baseStyle, elements, getEphemeralKey, stripe, booking.issuingCardId]);

  const refundsWithoutIssuings = getTotalRefundsExcludingIssuings(
    booking.refunds
  );

  const refundedAmount = getSumOfIssuingRefunds(booking.refunds);

  return (
    <div className="virtual-card-container">
      <div className="card-info">
        <CardStatus status={status} />

        {!cardIsCancelled ? (
          <>
            {refundsWithoutIssuings > 0 && (
              <CardRefunds amount={currencyFormatter(refundsWithoutIssuings)} />
            )}

            <CardDetails
              booking={booking}
              currencyFormatter={currencyFormatter}
              cardIsBlur={cardIsBlur}
              refundedAmount={refundedAmount}
            />

            <CardHistory status={status} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="card-visualizer">
        <CardVCC isBlur={cardIsBlur} />
      </div>
      <CardAlerts
        booking={booking}
        cardIsCancelled={cardIsCancelled}
        cardIsDeactivated={cardIsDeactivated}
      />
    </div>
  );
};

export default VirtualCard;
