import {
  AvailableOn,
  WeekDays,
} from 'app/components/commons/Experience/Availability/utils';

import { Experience } from './experiences';
import { Bed, Picture } from './rooms';

export enum PackageStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  HIDDEN = 'hidden',
}

export type ExtranetPicture = {
  id: number;
  createdAt: string;
  updatedAt: string;
  path: string;
  name: string;
  size: number;
  dimensions: {
    width: number;
    height: number;
  };
};

export type ExtranetExperience = {
  id: number;
  name: string | null;
  hotelId?: number;
  included: Boolean;
  price: number;
  priceDescription: string | null;
  description: string | null;
  categoryId: number;
  coverPicture: ExtranetPicture;
  emoji?: ExtranetPicture;
  priority: number;
  closedHoursBeforeCheckin?: number;
  brand: string | null;
  quantity: number | null;
  quantityDescription: string | null;
  duration: number | null;
  durationDescription: string | null;
  time: string;
  locations: string | null;
  maxQuantity: number | null;
  availableDays: Array<WeekDays> | null;
  availableDayLimits: Array<AvailableOn> | null;
  availableDates: Array<string> | null;
};

export type ExtranetRoom = {
  id: number;
  hotelId: number;
  categoryId: number;
  name: string;
  beds: Array<Bed>;
  categoryRoomCount?: number;
  coverPicture: ExtranetPicture;
  published: boolean;
};

export type ExtranetPackage = {
  id: number;
  aliasForHotel: string;
  aliasForUser?: string;
  slug: string;
  description: string;
  published: boolean;
  hidden: boolean;
  coverPictureId: number;
  hotelId: number;
  dayPackage: boolean;
  rooms: ExtranetRoom[];
  experiences: ExtranetExperience[];
};

export type PackageListItem = {
  id: number;
  aliasForHotel: string;
  aliasForUser?: string;
  coverLabel: string;
  hotelName: string;
  updatedAt: string;
  published: boolean;
  hidden: boolean;
  url: string;
  hotelId: number;
  coverPicture: Picture;
};

export type Package = {
  coverPicture: Picture;
  description: string;
  experiences: Array<Experience>;
  highlight: string;
  hotelId: number;
  id: number | null;
  mobileCoverPicture: Picture;
  name: string;
  rooms: Array<number>;
  sliderPicture: Picture;
  cover: Picture;
  slug: string;
  dayPackage: boolean;
  singleCustomerDiscount?: number;
  additionalAdultPrice?: number;
  childrenAllowed?: boolean;
  aliasForHotel?: string;
};

export type PackageCopywriting = {
  description: string;
  highlight: string;
  headlines: Array<PackageCopywritingHeadline>;
  name: string;
  experienceTitle?: string;
  aliasForHotel?: string;
};

export type PackageCopywritingHeadline = {
  experienceId?: number;
  emoji: Picture;
  valueProposition: string;
  featured: boolean;
};

export type PackageForm = {
  id?: number;
  hotelId: number;
  slug: string;
  dayPackage: boolean;
  experiences: Array<Experience>;
  rooms: Array<number>;
  singleCustomerDiscount?: number;
  additionalAdultPrice?: number;
};

export type CopywritingForm = {
  experienceTitle?: string;
  highlight: string;
  description: string;
  headlines: {
    emoji: Picture;
    valueProposition: string;
    experienceId: number;
    featured: boolean;
  }[];
};

export type PackageDiscover = {
  introPictures: Array<Picture>;
  roomPictures: Array<Picture>;
  packageExperiences: Array<Experience & { discover: boolean }>;
};

export type PackageCover = {
  coverLabel1?: string;
  coverLabel2?: string;
  horizontalCoverPicture: Picture;
  verticalCoverPicture: Picture;
  /**
   * @deprecated use one of horizontalCoverPicture or verticalCoverPicture instead
   */
  coverPicture: Picture;
  /**
   * @deprecated use one of horizontalCoverPicture or verticalCoverPicture instead
   */
  appPicture: Picture;
  /**
   * @deprecated use one of horizontalCoverPicture or verticalCoverPicture instead
   */
  sliderPicture: Picture;
};

export enum DisclaimerKind {
  ADVICE = 'ADVICE',
  ALERT = 'ALERT',
}

export type PackageDisclaimer = {
  id: number | null;
  kind: DisclaimerKind;
  text: string;
  startDate: string | null;
  endDate: string | null;
  packageId: number;
  temporaryId: number;
  priority: number;
};

export type PackageDisclaimerWithoutTemporaryId = Omit<
  PackageDisclaimer,
  'temporaryId'
>;
