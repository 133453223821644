import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';
import { useMemo } from 'react';

import { PackagesListQueryParam } from 'app/components/pages/Packages';
import {
  Package,
  PackageCopywriting,
  PackageCover,
  PackageDisclaimer,
  PackageDiscover,
  PackageListItem,
} from 'app/typings/packages';
import { PageMetaData } from 'app/typings/pagination';
import { apiRequest } from 'app/utils/request/api';

export const listPath = '/packages' as const;
export const listPaginatedPath = '/packages/paginated' as const;

export const packagePath = '/packages/:packageId' as const;
export const packageCopywritingPath =
  'packages/:packageId/copywriting' as const;
export const packageDiscoveryPath = 'packages/:packageId/discover' as const;
export const packageCoverPath = 'packages/:packageId/cover' as const;
export const packageDisclaimersPath =
  'packages/:packageId/disclaimers' as const;

export function usePackages() {
  return useQuery({
    queryKey: [listPath],
    queryFn: () => apiRequest({ path: listPath }) as Promise<PackageListItem[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}

export function usePaginatedPackages(queryParams: PackagesListQueryParam) {
  const key = useMemo(() => {
    return [
      listPaginatedPath,
      {
        queryParams,
      },
    ];
  }, [queryParams]);

  return useQuery({
    queryKey: key,
    queryFn: () =>
      apiRequest({ path: listPaginatedPath, queryParams }) as Promise<{
        packages: PackageListItem[];
        pagination: PageMetaData;
      }>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    keepPreviousData: true,
  });
}

export function useGetPackage(packageId?: number | null) {
  return useQuery({
    queryKey: [packagePath, { packageId }],
    queryFn: packageId
      ? () =>
          apiRequest({
            path: packagePath,
            params: {
              packageId: packageId.toString(),
            },
          }) as Promise<Package>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!packageId,
    onError: () => {
      message.error('Error fetching package');
    },
  });
}

export function useGetPackageCopywriting(packageId: number | null) {
  return useQuery({
    queryKey: [packageCopywritingPath, [packageId]],
    queryFn: packageId
      ? () =>
          apiRequest({
            path: packageCopywritingPath,
            params: {
              packageId: packageId.toString(),
            },
          }) as Promise<PackageCopywriting>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!packageId,
    onError: () => {
      message.error('Error fetching package copywriting');
    },
  });
}

export function useGetPackageDiscover(packageId: number | null) {
  return useQuery({
    queryKey: [packageDiscoveryPath, [packageId]],
    queryFn: packageId
      ? () =>
          apiRequest({
            path: packageDiscoveryPath,
            params: {
              packageId: packageId.toString(),
            },
          }) as Promise<PackageDiscover>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!packageId,
    onError: () => {
      message.error('Error fetching package copywriting');
    },
  });
}

export function useGetPackageCover(packageId: number | null) {
  return useQuery({
    queryKey: [packageCoverPath, [packageId]],
    queryFn: packageId
      ? () =>
          apiRequest({
            path: packageCoverPath,
            params: {
              packageId: packageId.toString(),
            },
          }) as Promise<PackageCover>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!packageId,
    onError: () => {
      message.error('Error fetching package copywriting');
    },
  });
}

export function useGetPackageDisclaimer(packageId: number | null) {
  return useQuery({
    queryKey: [packageDisclaimersPath, [packageId]],
    queryFn: packageId
      ? () =>
          apiRequest({
            path: packageDisclaimersPath,
            params: {
              packageId: packageId.toString(),
            },
          }) as Promise<PackageDisclaimer[]>
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!packageId,
    onError: () => {
      message.error('Error fetching package disclaimers');
    },
  });
}
