import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { HotelFeedbacks, PackageFeedbacks } from 'app/typings/feedbacks';
import { apiRequest } from 'app/utils/request/api';

const packageFeedbacksPath = '/extranet/:hotelId/packages/feedbacks' as const;
const hotelFeedbacksPath = '/extranet/:hotelId/feedbacks' as const;

export function usePackageFeedbacks(hotelId?: number) {
  return useQuery<
    { packages: Array<PackageFeedbacks> },
    unknown,
    { packages: Array<PackageFeedbacks> },
    (typeof packageFeedbacksPath | { hotelId?: number })[]
  >({
    queryKey: [packageFeedbacksPath, { hotelId }],
    queryFn: () =>
      apiRequest({
        path: packageFeedbacksPath,
        params: { hotelId: hotelId?.toString() ?? '' },
      }),
    onError: () => {
      message.error('Error fetching package feedbacks');
    },
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}

type QueryParamsType = {
  hotelId: number | undefined;
  pageNumber: number;
  search: string | undefined;
  packageIds: Array<number> | undefined;
  sorting: string | undefined;
  beginning: string | undefined;
  ending: string | undefined;
};

export function useHotelFeedbacks(
  queryParams: QueryParamsType,
  hotelId?: number
) {
  return useQuery<
    HotelFeedbacks,
    unknown,
    HotelFeedbacks,
    (
      | typeof hotelFeedbacksPath
      | { hotelId?: number; queryParams: QueryParamsType }
    )[]
  >({
    queryKey: [hotelFeedbacksPath, { hotelId, queryParams }],
    queryFn: () =>
      apiRequest({
        path: hotelFeedbacksPath,
        params: { hotelId: hotelId?.toString() ?? '' },
        queryParams,
      }),
    onError: () => {
      message.error("Error fetching hotel's feedbacks");
    },
    keepPreviousData: true,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}
