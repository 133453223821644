import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { RoomListElement } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';
import { SortOrder } from 'app/utils/sort';

export const roomsListPath = '/rooms' as const;

type RoomsListQueryParam = {
  search?: string;
  sort?: SortOrder;
  offset?: number;
  limit?: number;
  isPublished?: boolean;
};

const ALL_ROOMS = 100000;

export function useGetRooms(queryParams: RoomsListQueryParam = {}) {
  const formattedQueryParams = useMemo(() => {
    return {
      ...queryParams,
      limit: queryParams.limit ?? ALL_ROOMS,
    };
  }, [queryParams]);

  const key = useMemo(() => {
    return [
      roomsListPath,
      {
        ...formattedQueryParams,
      },
    ];
  }, [formattedQueryParams]);

  return useQuery({
    queryKey: key,
    queryFn: () =>
      apiRequest({
        path: roomsListPath,
        queryParams: formattedQueryParams,
      }) as Promise<RoomListElement[]>,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
