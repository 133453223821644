import { APISortOrder, SortOrder } from 'app/utils/sort';

export type WelcomeKitListCategory = 'stocks' | 'orders';

export enum STATUS_TYPE {
  DELIVERABLE = 'DELIVERABLE',
  PAUSED = 'PAUSED',
}

export type StatusType = (typeof STATUS_TYPE)[keyof typeof STATUS_TYPE];

export enum ORDER_STATUS {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
}

export type OrderType = (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS];

export enum PAUSE_REASON {
  ECO_FRIENDLY = 'Eco-friendly',
  CHURNED = 'Churned',
  NEW = 'New hotel',
  DELETED = 'Delete',
  OTHER = 'Other',
}

export type PauseReason = keyof typeof PAUSE_REASON;

export type StocksListQueryParam = {
  search?: string;
  sortOrder?: SortOrder;
  status?: Array<STATUS_TYPE>;
  pageNumber: number;
};

export type StockListItem = {
  id: number;
  hotelId: number;
  name: string;
  quantity: number;
  maxQuantity: number;
  pendingDeliveryQuantity: number;
  isPaused: boolean;
};

export type OrdersListQueryParam = {
  sortOrder?: APISortOrder;
  sortField?: keyof OrderListItem;
  status?: Array<ORDER_STATUS>;
};

export type OrderListItem = {
  id: number;
  creationDate: string;
  confirmationDate: string;
  totalQuantity: number;
  status: OrderType;
};
