import { Picture } from 'app/components/commons/Uploader/Types';
import { ExtranetBookingStatus } from 'app/redux/models/ExtranetBooking/ExtranetBooking';

import { PAX } from '../PAX';
import { PSP } from '../PSP';

import { Feedback } from './bookingFeedback';
import { Refund } from './bookingRefund';
import { Voucher } from './bookingVoucher';

export type Booking = {
  id: number;
  code: string;
  status: ExtranetBookingStatus;
  pax: PAX;
  checkin: string;
  checkout: string;
  customer: Customer;
  pkg: Package;
  room: Room;
  experiences: Experience[];
  comment: string;
  totalAmount: number;
  creditAmount: number;
  fees: number;
  chargedAmount: number;
  hotelAmount: number;
  taxAmount: number;
  cardInfo?: CardInfo;
  payedAt: string;
  updatedAt: string;
  psp?: PSP;
  currency: string;
  hasFeedback: boolean;
  basePackagePrice: number;
  basePackageDiscountPrice: number;
  giftCard: { couponCode: string; couponAmount: number };
  cancelledAt: string;
  paymentReceiptPath: string;
};

// TODO: mutualiser le type ?
export type Address = {
  street: string;
  zipCode: string;
  city: string;
  countryId: number;
};

type Package = {
  id: string;
  slug: string;
  hotelId: number;
  hotelName: string;
  aliasForHotel: string;
  aliasForUser?: string;
  email: string;
  stars: number;
  phone: string;
  address: Address;
  location: Location;
  coverPicture: Picture;
  dayPackage: boolean;
};

export type BookingDetails = {
  bookingSummary: BookingSummary;
  feedbacks: Feedback[];
  staycations: number[];
  giftCards: number[];
  customerAdvanced: CustomerAdvanced;
  voucher?: Voucher;
  refunds: Refund[];
};

export type CardInfo = {
  id: string;
  last4: string;
  brand: string;
};

export type Customer = {
  gender?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  guestFirstname?: string;
  guestLastname?: string;
  countrySlug?: string;
  id: number;
};
export type BookingSummary = {
  code: string;
  avatarId: string;
  customer: Customer;
  countryId: number;
  bookingId: number;
  checkin: string;
  checkout: string;
  status: BookingStatus;
  creditAmount: number;
  chargedAmount: number;
  totalAmount: number;
  cardInfo?: CardInfo;
  room: Room;
  comment: string;
  pkg: Package;
  paymentAdvanced: PaymentAdvanced;
  fees: number;
  experiences: Experience[];
  psp?: PSP;
  basePackagePrice: number;
  basePackageDiscountPrice: number;
  hotelAmount: number;
  paymentReceiptPath?: string;
  pax: PAX;
  issuingCardId?: string;
  issuingTransactionId?: string;
  issuingChargedAt?: string;
  issuingAuthorizationId?: string;
};

export type Experience = {
  id: number;
  name: string;
  quantity: number;
  category: BookingExperienceCategory;
  price?: number;
  included: boolean;
  priority: number;
  brand?: string;
  quantityDescription?: string;
  duration?: number;
  durationDescription?: string;
  time?: string;
  roomServiceAvailable: boolean;
  roomServiceIncluded: boolean;
  roomServicePrice?: number;
  accessMode?: string;
  locations?: string;
  experienceQuantity?: number;
};

type BookingExperienceCategory = {
  name: string;
  experienceType?: string;
  brand: boolean;
  duration: boolean;
  time: boolean;
  quantityTitle: boolean;
  locations: boolean;
  quantity: boolean;
};

type CustomerAdvanced = {
  avatarPath: string;
  birthDate: string;
};

type PaymentAdvanced = {
  createdAt: string;
  couponCode?: string;
  couponAmount?: number;
  refusalReason?: string;
};

export type Room = {
  id: number;
  name?: string;
  category: string;
  discountPrice: number;
  price: number;
  bedPricePerNight?: number;
  additionalAdultPricePerNight?: number;
  additionalChildPricePerNight?: number;
  singleCustomerDiscountPerNight?: number;
};

export enum BookingStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Expired = 'expired',
  Aborted = 'aborted',
  Rejected = 'rejected',
}

export type BookingListItem = {
  id: number;
  code: string;
  createdAt: string;
  checkin: string;
  hotelName: string;
  status: BookingStatus;
  psp: PSP;
  customer: Customer;
};
