import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { ExtranetBookingStatus } from 'app/redux/models/ExtranetBooking/ExtranetBooking';
import {
  BookingDetails,
  BookingListItem,
} from 'app/typings/extranetBookings/booking';
import { apiRequest } from 'app/utils/request/api';

const bookingByCodePath = '/extranet/:hotelId/bookings/:code' as const;

const bookingListPath = '/extranet/:hotelId/bookings' as const;

export function useGetBookingDetailByCode(hotelId?: number, code?: string) {
  return useQuery<{ booking: BookingDetails }>({
    queryKey: [bookingByCodePath, { hotelId, code }],
    queryFn:
      !!hotelId && !!code
        ? () =>
            apiRequest({
              path: bookingByCodePath,
              params: { hotelId: hotelId.toString(), code: code.toString() },
            })
        : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId && !!code,
  });
}

type QueryParams = {
  hotelId?: number;
  offset?: number;
  search?: string | undefined;
  sorting?: string | undefined;
  beginning?: string | undefined;
  ending?: string | undefined;
  status?: ExtranetBookingStatus[];
  vccStatus?: string[];
  packageIds: Array<number> | undefined;
};

export function useGetBookingsList(queryParams: QueryParams, hotelId?: number) {
  return useQuery<{ bookings: Array<BookingListItem> }>({
    queryKey: [bookingListPath, { hotelId, queryParams }],
    queryFn: !!hotelId
      ? () =>
          apiRequest({
            path: bookingListPath,
            params: { hotelId: hotelId.toString() },
            queryParams,
          })
      : undefined,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}
