import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import './Help.scss';

const { Panel } = Collapse;

const Help = () => {
  return (
    <Collapse defaultActiveKey={['1']} className="booking-help">
      <Panel header="I can’t charge my virtual card" key="1">
        <div className="help-text">
          Make sure the amount entered into your payment terminal (POS) matches
          exactly the amount displayed under the "Amount to charge" field above
          your virtual card.
        </div>
      </Panel>
      <Panel header="I am trying to issue a refund" key="2">
        <div className="help-text">
          Enter the exact amount displayed under "Amount to refund" into your
          payment terminal (POS).
          <br />
          <br />
          If the VCC has not been debited yet, you will need to:
          <ol className="help-list">
            <li>
              Fully charge the virtual card on your payment terminal (POS).
            </li>
            <li>
              Proceed with the refund by crediting the refund amount back to the
              virtual card.
            </li>
          </ol>
          <InfoCircleOutlined /> Once you have issued the refund, please wait up
          to 72h for the refund status to update.
        </div>
      </Panel>
      <Panel header="A client is asking for a refund" key="3">
        <div className="help-text">
          Contact Staycation to initiate the refund request and inform the
          client that the refund will be managed by Staycation.
        </div>
      </Panel>
      <Panel header="Others issues" key="4">
        <div className="help-text">Please use the Assistance button.</div>
      </Panel>
    </Collapse>
  );
};

export default Help;
