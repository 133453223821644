import { StarFilled } from '@ant-design/icons';
import { Checkbox } from 'antd';

import { PackageFeedbacks } from 'app/typings/feedbacks';
import { ExtranetPackage } from 'app/typings/packages';

import './PackageSelector.scss';

type PackageSelectorProps = {
  pkg: PackageFeedbacks;
  packageList: ExtranetPackage[];
  filterValue: number[];
  setFilterValue: (value: number[]) => void;
};

const PackageSelector = ({
  pkg,
  packageList,
  filterValue,
  setFilterValue,
}: PackageSelectorProps) => {
  const onChangeSelect = () => {
    if (filterValue.includes(pkg.id)) {
      setFilterValue(filterValue.filter((filter) => filter !== pkg.id));
    } else {
      setFilterValue([...filterValue, pkg.id]);
    }
  };

  const hotelAlias = packageList.find(
    (pkgList) => pkgList.id === pkg.id
  )?.aliasForHotel;

  const packageName = hotelAlias?.trim() ? hotelAlias : `Package #${pkg.id}`;

  return (
    <div className="package-selector-container">
      <div className="checkbox-container">
        <Checkbox
          checked={filterValue.includes(pkg.id)}
          onChange={onChangeSelect}
        />
      </div>
      <div className="package-selector-content">
        <div className="package-name">{packageName}</div>
        <div className="package-rating">
          <div className="rating">
            <StarFilled />
            <div>{Number(pkg.averageScore.toFixed(1))}</div>
          </div>
          <div className="nb-feedbacks">({pkg.feedbacksNumber})</div>
        </div>
      </div>
    </div>
  );
};

export default PackageSelector;
