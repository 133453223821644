import { InfoCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

import { getTooltipText } from 'app/components/pages/Extranet/ExtranetBookings/BookingDetail/utils';
import { VccStatusData } from 'app/typings/vcc';

const OFFSET_TOOLTIP = [17, 0];

type CardStatusProps = {
  status: VccStatusData;
};

const CardStatus = ({ status }: CardStatusProps) => {
  const tooltipText = getTooltipText(status.status);

  return (
    <div className="status">
      <Tag color={status.color}>{status.text}</Tag>
      {!!tooltipText && (
        <Tooltip
          align={{ targetOffset: OFFSET_TOOLTIP }}
          title={tooltipText}
          placement="bottomLeft"
        >
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </div>
  );
};

export default CardStatus;
