import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors';
import { LiteralUnion } from 'type-fest';

export const DEACTIVATE_AFTER_X_MONTHS = 6;

export enum VccStatus {
  Inactive = 'INACTIVE',
  Cancelled = 'CANCELLED',
  ReadyToCharge = 'READY_TO_CHARGE',
  FullyCharged = 'FULLY_CHARGED',
  PendingRefund = 'PENDING_REFUND',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  FullyRefunded = 'FULLY_REFUNDED',
  Deactivated = 'DEACTIVATED',
}

export type VccStatusData = {
  status: VccStatus;
  text: string;
  color: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
  history: Array<string>;
};

export type VccStatusDisplayInfo = {
  text: string;
  color: LiteralUnion<PresetColorType | PresetStatusColorType, string>;
  status: VccStatus;
};
