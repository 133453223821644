import { Table } from 'antd';
import Column from 'antd/lib/table/Column';

import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';
import {
  DeliveriesListParams,
  Delivery,
  OrderStatus,
} from 'app/typings/WelcomeKit/deliveries';
import { DeliveryStatus } from 'app/typings/WelcomeKit/deliveries';
import { PageMetaData } from 'app/typings/pagination';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/utils/pagination';
import { formatGatewaySort } from 'app/utils/sort';

import ModalCheckBox from '../../Extranet/ExtranetBookings/ModalCheckBox';

import './Deliveries.scss';
import { DeliveryMenuActions } from './DeliveryMenuActions';

type Props = {
  items: Delivery[];
  pagination?: PageMetaData;
  loading: boolean;
  params: DeliveriesListParams;
  setParams: (params: DeliveriesListParams) => void;
  orderStatus?: OrderStatus;
};

const statusToRender: {
  status: DeliveryStatus;
  label: string;
  type: 'success' | 'default' | 'error' | 'warning';
}[] = [
  { status: DeliveryStatus.Draft, label: 'Draft', type: 'warning' },
  { status: DeliveryStatus.Delivered, label: 'Delivered', type: 'success' },
  {
    status: DeliveryStatus.NotDelivered,
    label: 'Not delivered',
    type: 'error',
  },
  { status: DeliveryStatus.Pending, label: 'Pending', type: 'warning' },
  { status: DeliveryStatus.Cancelled, label: 'Cancelled', type: 'default' },
];

const statusCheckBoxItems = [
  { value: DeliveryStatus.Draft, label: 'Draft' },
  { value: DeliveryStatus.Cancelled, label: 'Cancelled' },
  { value: DeliveryStatus.NotDelivered, label: 'Not Delivered' },
  { value: DeliveryStatus.Pending, label: 'Pending' },
  { value: DeliveryStatus.Delivered, label: 'Delivered' },
];

export const DeliveriesList = ({
  items,
  pagination,
  loading,
  params,
  setParams,
  orderStatus,
}: Props) => {
  const renderAddress = (_: string, item: Delivery) => (
    <div>
      <div>{item.street}</div>
      <div className="address-city">
        {item.zipCode}, {item.city}
      </div>
    </div>
  );

  const renderName = (_: string, item: Delivery) => (
    <div>{item.hotel.name}</div>
  );

  const renderStatus = (_: string, item: Delivery) => {
    const statusParams = statusToRender.find((sr) => sr.status === item.status);

    if (statusParams) {
      return (
        <div className="line-tag">
          <TagWithIcon text={statusParams.label} type={statusParams.type} />
        </div>
      );
    }

    return <div>{item.status}</div>;
  };

  const actionMenu = (delivery: Delivery, orderStatus?: OrderStatus) => {
    if (delivery.status !== DeliveryStatus.Pending) {
      return (
        <DeliveryMenuActions delivery={delivery} orderStatus={orderStatus} />
      );
    }

    return <></>;
  };

  const handleStatusChange = (nextStatus: DeliveryStatus[]) => {
    setParams({ ...params, status: nextStatus });
  };

  return (
    <Table
      className="delivery-table"
      loading={loading}
      dataSource={items}
      rowKey={'id'}
      pagination={{
        simple: true,
        showSizeChanger: false,
        pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
        current: pagination?.pageNumber ?? DEFAULT_PAGE,
        total: pagination?.itemCount || 1,
        position: ['topRight', 'bottomRight'],
      }}
      onChange={(paginationConfig, ___, sorter) => {
        // @ts-ignore
        const { field, order } = sorter;
        const currentPage = paginationConfig.current ?? DEFAULT_PAGE;

        if (pagination?.pageNumber !== currentPage) {
          setParams({ ...params, pageNumber: currentPage });
        } else if (field === 'quantity') {
          setParams({ ...params, sortOrder: formatGatewaySort(order) });
        }
      }}
    >
      <Column key="id" title="Hotel name" render={renderName} />
      <Column key="address" title="Shipping address" render={renderAddress} />
      <Column key="phoneNumber" title="Phone number" dataIndex="phone" />
      <Column key="quantity" title="Quantity" dataIndex="quantity" sorter />
      <Column
        key="status"
        width="140px"
        title={
          <ModalCheckBox
            title="Status"
            value={params?.status || []}
            onChange={handleStatusChange}
            items={statusCheckBoxItems}
          />
        }
        render={renderStatus}
        filtered
      />
      <Column
        key="action"
        width="50px"
        render={(_, d: Delivery) => actionMenu(d, orderStatus)}
        onCell={() => ({ onClick: (e) => e.stopPropagation() })}
        align="center"
      />
    </Table>
  );
};
