import { WelcomeKitListCategory } from 'app/typings/WelcomeKit/welcomeKit';

export const getWelcomeKitListCategory = (query: string | null) => {
  return isWelcomeKitListCategory(query) ? query : 'stocks';
};

export const isWelcomeKitListCategory = (
  query: string | null
): query is WelcomeKitListCategory => {
  return !!query && (query === 'stocks' || query === 'orders');
};
