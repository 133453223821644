import {
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  StarFilled,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Layout,
  Pagination,
  Table,
  TablePaginationConfig,
} from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { endOfISOWeek, startOfISOWeek } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import type { RangeValue } from 'rc-picker/lib/interface';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import { TaskCenterContext } from 'app/context/TaskCenterContext/TaskCenterContext';
import { useGetHotelPackages } from 'app/hooks/data/packages/usePackageExtranet';
import {
  useHotelFeedbacks,
  usePackageFeedbacks,
} from 'app/hooks/data/useExtranetFeedbacks';
import { useAmplitudeViewPage } from 'app/hooks/useAmplitude/useAmplitude';
import { useAppSelector } from 'app/redux/hooks';
import { selectHotelAdminHotelId } from 'app/redux/selectors/hotelAdmin';
import { Feedbacks } from 'app/typings/feedbacks';
import {
  formatDateToDayMonthYear,
  formatDateWithoutHour,
} from 'app/utils/dates';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/utils/pagination';
import { scrollToTop } from 'app/utils/scroll';
import { formatSorting } from 'app/utils/sortAndFilter';

import { ExtranetHeader } from '../commons/ExtranetHeader/ExtranetHeader';
import PackageSelector from '../commons/PackageSelector/PackageSelector';

import './ExtranetFeedbacks.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = DatePicker;

const { Content } = Layout;
const { Column } = Table;

declare type SortOrder = 'descend' | 'ascend' | null;

const PAGE = 'Reviews';

const SIZE_OF_PACKAGE_INSDE_LIST = 287 + 16;

const ExtranetFeedbacks = () => {
  const hotelId = useAppSelector(selectHotelAdminHotelId);

  const { setPage } = useContext(TaskCenterContext);
  const [search, setSearch] = useState<string>('');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sorting, setSorting] = useState<{
    field: string;
    order: SortOrder;
  }>();

  const { data: packageFeedbacksList, isLoading: packageFeedbacksLoading } =
    usePackageFeedbacks(hotelId);

  //TODO : Replace with context
  const { data: packagesList, isLoading: packagesListLoading } =
    useGetHotelPackages(hotelId ?? 0);

  const publishedPackagesList = packagesList?.packages.filter(
    (pkg) => pkg.published && !pkg.hidden
  );

  const isMultiPackage =
    !!packageFeedbacksList?.packages &&
    packageFeedbacksList.packages.length > 1;

  const [packageIdsValue, setPackageIdsValue] = useState<Array<number>>([]);

  useEffect(() => {
    if (!packageFeedbacksLoading && !!packageFeedbacksList) {
      setPackageIdsValue(
        packageFeedbacksList.packages.map((pkg) => pkg.id) ?? []
      );
    }
  }, [packageFeedbacksList, packageFeedbacksLoading]);

  useEffect(() => {
    setPage(PAGE);
  });

  const debouncedSearch = useDebounce(search);

  const makePayload = useCallback(() => {
    const hasSorting = sorting?.field && sorting?.order;
    const orderField = !sorting?.order ? 'none' : sorting.order;

    return {
      hotelId,
      pageNumber: pageNumber,
      search: debouncedSearch || undefined,
      packageIds: packageIdsValue || undefined,
      sorting: hasSorting
        ? formatSorting(sorting?.field, orderField)
        : undefined,
      beginning: startDate,
      ending: endDate,
    };
  }, [
    hotelId,
    pageNumber,
    debouncedSearch,
    packageIdsValue,
    sorting,
    startDate,
    endDate,
  ]);

  const { data: hotelFeedbacksList } = useHotelFeedbacks(
    makePayload(),
    hotelId
  );

  const pagination = hotelFeedbacksList?.pagination;

  useEffect(() => {
    setPageNumber(1);
  }, [
    setPageNumber,
    hotelId,
    search,
    sorting,
    startDate,
    endDate,
    packageIdsValue,
  ]);

  useAmplitudeViewPage({ page: 'reviews' });

  const handleFilterDate = (range: RangeValue<Date>) => {
    if (!range || !range[0] || !range[1]) {
      setStartDate(undefined);
      setEndDate(undefined);
    } else {
      setStartDate(formatDateWithoutHour(range[0]));
      setEndDate(formatDateWithoutHour(range[1]));
    }
  };

  const onChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Feedbacks> | SorterResult<Feedbacks>[]
  ) => {
    const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter || {};

    const packageIdFilter = filters.packageId as number[] | null | undefined;

    setPackageIdsValue(packageIdFilter?.length ? packageIdFilter : []);

    if (field) {
      const fieldValue = field as string;

      setSorting({ field: fieldValue, order: order ?? null });
    }
  };

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
        <RangePicker
          onChange={handleFilterDate}
          format="EE DD MMM"
          ranges={{
            Today: [new Date(), new Date()],
            'This Week': [startOfISOWeek(new Date()), endOfISOWeek(new Date())],
          }}
          className="range-picker"
          size="large"
        />
      </div>
      <Pagination
        pageSize={pagination?.pageSize ?? DEFAULT_PAGE_SIZE}
        current={pagination?.pageNumber ?? DEFAULT_PAGE}
        showSizeChanger={false}
        simple={true}
        total={pagination?.itemCount ?? 1}
        onChange={(page) => setPageNumber(page)}
      />
    </div>
  );

  const grayCell = (title: string) => (
    <div style={{ color: '#8C8C8C', textAlign: 'center' }}>{title}</div>
  );

  const renderHotelMarks = () => {
    if (isMultiPackage || packageFeedbacksLoading) {
      return null;
    }

    return (
      <span className="hotel-marks">
        <span className="star">
          <StarFilled />
        </span>
        <span className="average">
          {packageFeedbacksList?.packages[0].averageScore.toFixed(1)}
        </span>
        <span className="counter">
          ({packageFeedbacksList?.packages[0].feedbacksNumber})
        </span>
      </span>
    );
  };

  const onClickScroll = (toLeft: boolean) => {
    const element = document.getElementById('packages-list');

    if (element) {
      element.scrollBy({
        behavior: 'smooth',
        left: toLeft ? -SIZE_OF_PACKAGE_INSDE_LIST : SIZE_OF_PACKAGE_INSDE_LIST,
      });
    }
  };

  const sizeOfPackagesList =
    document.getElementById('packages-review-summary')?.offsetWidth ?? 0;

  const hasScroll =
    !!packageFeedbacksList?.packages &&
    packageFeedbacksList?.packages.length * SIZE_OF_PACKAGE_INSDE_LIST >
      sizeOfPackagesList;

  const [isOnLeftBorder, setIsOnLeftBorder] = useState(true);
  const [isOnRightBorder, setIsOnRightBorder] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollLeft, clientWidth, scrollWidth } = e.currentTarget;

    setIsOnLeftBorder(scrollLeft === 0);
    setIsOnRightBorder(scrollLeft + clientWidth === scrollWidth);
  };

  return (
    <Layout className="extranet-table">
      <ExtranetHeader page={PAGE} subTitle={renderHotelMarks()} />
      <Content className="body">
        <div className="packages-review-summary" id="packages-review-summary">
          {hasScroll && !isOnLeftBorder && (
            <div className="scroll-button-container scroll-left">
              <Button
                className="scroll-button"
                onClick={() => onClickScroll(true)}
              >
                <LeftOutlined />
              </Button>
            </div>
          )}
          <div
            className="packages-list"
            id="packages-list"
            onScroll={handleScroll}
          >
            {isMultiPackage &&
              packageFeedbacksList?.packages.map((pkg) => {
                return (
                  <Fragment key={pkg.id}>
                    <PackageSelector
                      pkg={pkg}
                      packageList={publishedPackagesList ?? []}
                      filterValue={packageIdsValue}
                      setFilterValue={setPackageIdsValue}
                    />
                  </Fragment>
                );
              })}
          </div>
          {hasScroll && !isOnRightBorder && (
            <div className="scroll-button-container scroll-right">
              <Button
                className="scroll-button"
                onClick={() => onClickScroll(false)}
              >
                <RightOutlined />
              </Button>
            </div>
          )}
        </div>

        <Table
          dataSource={hotelFeedbacksList?.feedbacks ?? []}
          pagination={false}
          title={header}
          loading={packagesListLoading}
          onChange={onChange}
        >
          <Column title="Guest" dataIndex="guestName" />
          {isMultiPackage && (
            <Column
              title="Package"
              dataIndex="packageId"
              render={(val) =>
                publishedPackagesList?.find((pkg) => pkg.id === val)
                  ?.aliasForHotel
              }
              filters={
                publishedPackagesList?.map((pkg) => ({
                  text: pkg.aliasForHotel,
                  value: pkg.id,
                })) ?? []
              }
              defaultFilteredValue={packageIdsValue.map((filter) => filter)}
              filteredValue={packageIdsValue.map((filter) => filter)}
              filterResetToDefaultFilteredValue
            />
          )}
          <Column
            title="Published"
            dataIndex="publishedAt"
            render={formatDateToDayMonthYear}
            sorter
            sortOrder={
              sorting?.field === 'publishedAt' ? sorting.order : undefined
            }
          />
          <Column
            title="Global"
            dataIndex="globalScore"
            render={(score) => score.toFixed(1)}
            sorter
            sortOrder={
              sorting?.field === 'globalScore' ? sorting.order : undefined
            }
          />
          <Column
            title={grayCell('Experience')}
            dataIndex="experienceScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Service')}
            dataIndex="serviceScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Vibe')}
            dataIndex="vibeScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Room')}
            dataIndex="roomScore"
            render={grayCell}
          />
          <Column
            title={grayCell('Breakfast')}
            dataIndex="breakfastScore"
            render={grayCell}
          />
          <Column
            title="Comment"
            dataIndex="comment"
            render={(comment) => (
              <div style={{ lineHeight: '18px' }}>{comment}</div>
            )}
            width={400}
          />
        </Table>
        <div className="bottom-pagination">
          <Pagination
            pageSize={pagination?.pageSize ?? DEFAULT_PAGE_SIZE}
            current={pagination?.pageNumber ?? DEFAULT_PAGE}
            showSizeChanger={false}
            simple={true}
            total={pagination?.itemCount ?? 1}
            onChange={(page) => {
              setPageNumber(page);
              scrollToTop();
            }}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default ExtranetFeedbacks;
