import { BulbOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';

import Features from 'app/components/commons/Features/Features';
import BasicInfo from 'app/components/commons/Room/BasicInfo/BasicInfo';
import Pictures from 'app/components/commons/Room/Pictures/Pictures';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { Room } from 'app/typings';

import { RoomFeature } from '../../../Types';

import BedsBuilder from './BedBuilder/BedsBuilder';
import './RoomModal.scss';

export const RoomModal: FC<Props> = ({
  saveRoom,
  close,
  room,
  roomFeatures,
  categories,
  hotelId,
}) => {
  const [form] = Form.useForm();

  const basicFeatureIds = useMemo(
    () =>
      roomFeatures
        .filter((feature) => feature.basic)
        .map((feature) => feature.id),
    [roomFeatures]
  );

  const featuresByCategory = useMemo(() => {
    return roomFeatures.reduce((acc: any, feature) => {
      if (!acc[feature.category]) {
        acc[feature.category] = [];
      }

      acc[feature.category].push(feature);

      return acc;
    }, {});
  }, [roomFeatures]);

  const validate = ({
    area,
    beds,
    categoryId,
    categoryRoomCount,
    pictures,
  }: any) => {
    if (!Number.isInteger(area)) {
      return false;
    }

    if (!beds) {
      return false;
    }

    if (
      beds.some((bed: any, index: any) => !Number.isInteger(bed.categoryId))
    ) {
      return false;
    }

    if (!Number.isInteger(categoryId)) {
      return false;
    }

    if (!Number.isInteger(categoryRoomCount)) {
      return false;
    }

    if (!pictures?.length) {
      return false;
    }

    return true;
  };

  const renderFooter = (values: any) => {
    const isValid = validate(values);

    return (
      <>
        <Button key="cancel" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          key="submit"
          onClick={() => form.submit()}
          disabled={!isValid}
          type="primary"
        >
          Save
        </Button>
      </>
    );
  };

  const onFinish = async (values: any) => {
    const formatted = {
      ...room,
      ...values,
      beds: values.beds.map((bed: any) => ({
        ...bed,
        included: bed.included !== false,
        price: bed.price || 0,
      })),
      hasPRM: values.hasPRM || false,
      hotelId,
    };

    saveRoom(formatted);
    close();
  };

  const initialValues = {
    ...room,
    // @ts-ignore
    beds: room.beds?.map((bed) => ({
      ...bed,
      included: bed.included,
    })) || [{}],
    // @ts-ignore
    featureIds: room.featureIds || basicFeatureIds,
    // @ts-ignore
    hasPRM: room.hasPRM,
    // @ts-ignore
    pictures: room.pictures || [],
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      {(values) => (
        <Modal
          title="Edit room category"
          width={800}
          className="room-modal-builder"
          wrapClassName="hotel-builder-modal"
          onCancel={() => close()}
          footer={renderFooter(values)}
          open
          centered
        >
          <div>
            <div className="title">Basic information</div>
            <div className="block">
              <BasicInfo values={values} />
            </div>
          </div>
          <div>
            <div className="title">Beds</div>
            <div className="block">
              <Form.List name="beds">
                {(fields, { add: addBed, remove: removeBed }) => (
                  <BedsBuilder
                    fields={fields}
                    add={addBed}
                    remove={removeBed}
                    values={values}
                  />
                )}
              </Form.List>
            </div>
          </div>
          <div>
            <div className="title">Features</div>
            <div className="block">
              <Form.Item name="featureIds">
                <Features featuresByCategory={featuresByCategory} />
              </Form.Item>
            </div>
          </div>
          <div>
            <div className="title">Photos</div>
            <div className="block">
              <Form.Item name="pictures">
                <Pictures
                  roomCategoryId={(room as Room)?.categoryId}
                  categories={categories}
                  hotelId={hotelId}
                  previewable
                  removable
                  picturesCategories={[
                    { name: 'Media gallery', type: 'official' },
                  ]}
                  kind="official"
                />
              </Form.Item>

              <div className="cool-to-know">
                <div className="border" />
                <div className="content">
                  <div className="title">
                    <BulbOutlined />
                    <div>Cool to know</div>
                  </div>
                  <div className="text">
                    Guests are more likely to book your package if there are at
                    least 4 pictures of your room and bathroom.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};

type Props = {
  saveRoom: (values: Room) => void;
  close: () => void;
  room: Room | {};
  roomFeatures: Array<RoomFeature>;
  categories: { [id: number]: string };
  hotelId: number;
};

const mapStateToProps = (state: any) => ({
  roomFeatures: state.conf.roomFeatures,
});

export default connect(mapStateToProps)(RoomModal);
