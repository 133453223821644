import { InfoCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { useGetHotel } from 'app/hooks/data/hotels/useHotel';
import { useRoomCategories } from 'app/hooks/data/rooms/useRoomCategories';
import { useExperiencesCategories } from 'app/hooks/data/useExperience';
import { BookingDetails } from 'app/typings/extranetBookings/booking';

import { getTotalPrice } from '../../utils';

import './PaymentDetails.scss';
import RenderExtra from './_components/RenderExtra';
import RenderPaxOptions from './_components/RenderPaxOptions';
import { RenderPaymentDetail } from './_components/RenderPaymentDetail';

type PaymentDetailsProps = {
  booking: BookingDetails;
  hotelId?: number;
};

const PaymentDetails = ({ booking, hotelId }: PaymentDetailsProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);
  const { data: roomCategories } = useRoomCategories();
  const { data: expCategories } = useExperiencesCategories();
  const { data: hotel } = useGetHotel(hotelId);

  const getRoomCategorieName = (categoryId: number) =>
    roomCategories?.find((roomCat) => roomCat.id === categoryId)?.name;

  const getIncludedExperiences = () =>
    expCategories
      ?.filter((expCat) =>
        booking.experiences
          .filter((exp) => exp.included && exp.hotelId)
          .find((exp) => exp.category.id === expCat.id)
      )
      .map((expCat) => expCat.name)
      .join(' + ');

  return (
    <div className="payment-detail-container">
      <RenderPaymentDetail
        title="Room"
        content={
          booking.room.name
            ? booking.room.name
            : getRoomCategorieName(booking.room.categoryId) ?? ''
        }
        value={currencyFormatter(getTotalPrice(booking, roomCategories) || 0)}
      />
      <RenderPaxOptions booking={booking} />
      <RenderExtra booking={booking} />
      <RenderPaymentDetail
        title={`Package reminder (${booking.aliasForHotel})`}
        content={getIncludedExperiences() ?? ''}
        value="Included"
      />
      <RenderPaymentDetail
        content="Total paid incl. VAT"
        value={currencyFormatter(booking.totalAmount || 0)}
        contentAsTitle
      />
      <RenderPaymentDetail
        content="Net commission amount incl. VAT"
        value={currencyFormatter(booking.hotelAmount)}
        description={
          booking.dayPackage
            ? 'Free cancellation up to 24 hours in advance'
            : 'No cancellations or refunds'
        }
        removeDivider
      />
      {!hotel?.issuingActivated && !booking.issuingCardId && (
        <Alert
          className="blue-variant"
          type="info"
          message="Please save an imprint of the guest’s credit card"
          icon={<InfoCircleFilled />}
          showIcon
        />
      )}
      {hotel?.issuingActivated && !booking.issuingCardId && (
        <Alert
          type="warning"
          message={
            <div>
              There is no virtual card for this booking.
              <br />
              Your payment will be made via bank transfer.
            </div>
          }
          icon={<InfoCircleFilled />}
          showIcon
        />
      )}
    </div>
  );
};

export default PaymentDetails;
