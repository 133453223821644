import { Layout, PageHeader } from 'antd';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CurrencyWrapper from 'app/components/commons/Currency/CurrencyWrapper/CurrencyWrapper';
import Spinner from 'app/components/commons/Spinner';
import { useAdminBooking } from 'app/hooks/data/adminBookingDetails/useAdminBooking';
import { useEditBookingReview } from 'app/hooks/data/adminBookingDetails/useAdminBookingFeedback';
import {
  useResendBookingConfirmation,
  useResendBookingHotelConfirmation,
} from 'app/hooks/data/adminBookingDetails/useAdminBookingMailConfirmation';
import { useRefundBooking } from 'app/hooks/data/adminBookingDetails/useAdminBookingRefund';
import { PSP } from 'app/typings/PSP';
import type { Feedback as FeedbackType } from 'app/typings/adminBookings/bookingFeedback';

import 'styles/pages/Booking/booking-details.scss';

import Contact from './Contact/Contact';
import EditButton from './EditButton/EditButton';
import EditEmailModal from './EditEmailModal/EditEmailModal';
import Feedback from './Feedback/Feedback';
import Guest from './Guest/Guest';
import Payment from './Payment/Payment';
import RefundModal from './RefundModal/RefundModal';
import Refunds from './Refunds/Refunds';
import Reservation from './Reservation/Reservation';
import ReviewEditModal from './ReviewEditModal/ReviewEditModal';
import Summary from './Summary/Summary';
import User from './User/User';
import Voucher from './Voucher/Voucher';
import VoucherModal from './VoucherModal/VoucherModal';

const BookingDetails = () => {
  const history = useHistory();
  const { bookingCode } = useParams<{ bookingCode: string }>();

  const {
    data: bookingDetails,
    isLoading,
    refetch: reloadBooking,
  } = useAdminBooking(bookingCode);

  const { mutateAsync: onRefundBooking } = useRefundBooking(bookingCode);

  const { mutateAsync: resendConfirmationMail } =
    useResendBookingConfirmation(bookingCode);

  const { mutateAsync: resendHotelConfirmationMail } =
    useResendBookingHotelConfirmation(bookingCode);

  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [feedbackInModal, setFeedbackInModal] = useState<
    FeedbackType | undefined
  >(undefined);
  const [resendModalVisible, setResendModalVisible] = useState(false);
  const [resendHotelModalVisible, setResendHotelModalVisible] = useState(false);
  const [isVoucherModalVisible, setIsVoucherModalVisible] = useState(false);
  const [isEditEmailModalVisible, setIsEditEmailModalVisible] = useState(false);

  const { mutateAsync: editBookingReview } = useEditBookingReview(
    bookingCode,
    feedbackInModal?.id
  );

  const back = () => {
    history.goBack();
  };

  if (
    bookingDetails === null ||
    bookingDetails?.bookingSummary.code !== bookingCode
  ) {
    return null;
  }

  const { bookingSummary, refunds, feedbacks } = bookingDetails;
  const { code, customer } = bookingSummary;

  return (
    <Layout className="booking-details">
      <Spinner spinning={isLoading}>
        <PageHeader
          className="header"
          onBack={back}
          title={`Booking ${code}`}
          extra={
            <EditButton onClick={() => setIsEditEmailModalVisible(true)} />
          }
        />
        <CurrencyWrapper countryId={bookingSummary.countryId}>
          <div className="body">
            <div className="left-part">
              <User bookingDetails={bookingDetails} />
              <Guest customer={customer} />
              <Contact customer={customer} />
            </div>
            <div className="right-part">
              <Reservation
                bookingSummary={bookingSummary}
                resendModalVisible={resendModalVisible}
                resendConfirmationMail={resendConfirmationMail}
                setResendModalVisible={setResendModalVisible}
                resendHotelModalVisible={resendHotelModalVisible}
                resendHotelConfirmationMail={resendHotelConfirmationMail}
                setResendHotelModalVisible={setResendHotelModalVisible}
              />
              {refundModalVisible && (
                <RefundModal
                  bookingCode={bookingCode}
                  isVccBooking={bookingSummary.psp === PSP.StripeIssuing}
                  isVccDebited={
                    !!bookingSummary.issuingAuthorizationId &&
                    !!bookingSummary.issuingChargedAt
                  }
                  title="Refund Booking"
                  onOk={onRefundBooking}
                  onCancel={() => setRefundModalVisible(false)}
                />
              )}
              <Summary bookingSummary={bookingSummary} />
              <Payment
                setIsVoucherModalVisible={setIsVoucherModalVisible}
                bookingDetails={bookingDetails}
                setRefundModalVisible={setRefundModalVisible}
              />
              <Voucher
                setIsVoucherModalVisible={setIsVoucherModalVisible}
                voucher={bookingDetails.voucher}
              />
              <VoucherModal
                isVisible={isVoucherModalVisible}
                setIsVoucherModalVisible={setIsVoucherModalVisible}
                bookingDetails={bookingDetails}
                reloadBookingDetails={reloadBooking}
              />
              {feedbacks.map((feedback) => (
                <Feedback
                  bookingCode={bookingCode}
                  feedback={feedback}
                  onEdit={() => setFeedbackInModal(feedback)}
                  reloadBooking={reloadBooking}
                />
              ))}
              {refunds && !!refunds.length && <Refunds refunds={refunds} />}
              <EditEmailModal
                bookingCode={bookingCode}
                email={customer.email}
                visible={isEditEmailModalVisible}
                onClose={async () => {
                  await reloadBooking();
                  setIsEditEmailModalVisible(false);
                }}
              />
            </div>
          </div>
        </CurrencyWrapper>
      </Spinner>
      {feedbackInModal && (
        <ReviewEditModal
          visible
          review={feedbackInModal?.comment || ''}
          onCancel={() => setFeedbackInModal(undefined)}
          onSubmit={async (review) => {
            if (!feedbackInModal) {
              return;
            }

            await editBookingReview({
              comment: review,
            });
            await reloadBooking();
            setFeedbackInModal(undefined);
          }}
        />
      )}
    </Layout>
  );
};

export default BookingDetails;
