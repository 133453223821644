import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { Room } from 'app/typings';
import { apiMutation, apiRequest } from 'app/utils/request/api';

import { roomsListPath } from './useGetRooms';

const path = '/rooms/:id' as const;
const savePath = '/rooms' as const;
const deletePath = '/rooms/:id/soft' as const;

export function useGetRoom(id?: string) {
  return useQuery({
    queryKey: [path, { id }],
    queryFn: id
      ? () => apiRequest({ path, params: { id } }) as Promise<Room>
      : () => Promise.resolve(undefined),
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!id,
  });
}

export function useSaveRoom() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Room) =>
      apiMutation(
        'POST',
        {
          path: savePath,
        },
        payload
      ) as Promise<number>,
    onSuccess: (id) => {
      message.success('Room successfully saved');

      queryClient.invalidateQueries({
        queryKey: [path, { id: id.toString() }],
      });

      queryClient.invalidateQueries({
        queryKey: [roomsListPath],
      });
    },
    onError: () => {
      message.error('Failed to save room');
    },
  });
}

export function useDeleteRoom(hotelId?: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (roomId: number) =>
      apiMutation('DELETE', {
        path: deletePath,
        params: { id: roomId.toString() },
      }) as Promise<void>,
    onSuccess: (_, roomId) => {
      queryClient.invalidateQueries({
        queryKey: [path, { id: roomId }],
      });

      queryClient.invalidateQueries({
        queryKey: ['/hotels/:id/rooms', { id: hotelId }],
      });
      message.success(`Room ${roomId} deleted`);
    },
    onError: () => {
      message.error('Not possible: this room is used somewhere else');
    },
  });
}
