import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TagWithIcon from 'app/components/commons/TagWithIcon/TagWithIcon';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import { useDownloadOrder } from 'app/hooks/data/welcomeKit/orders/useDownloadOrder';
import { useConfirmOrder } from 'app/hooks/data/welcomeKit/orders/useOrder';
import { useGetOrderList } from 'app/hooks/data/welcomeKit/useGetWelcomeKitLists';
import {
  ORDER_STATUS,
  OrderListItem,
  OrderType,
} from 'app/typings/WelcomeKit/welcomeKit';
import { renderDate } from 'app/utils/dates';
import { DEFAULT_PAGE_SIZE } from 'app/utils/pagination';
import { APISortOrder, formatSortingForAPI } from 'app/utils/sort';
import {
  parseStringToArray,
  parseUppercaseTextToCapitalize,
} from 'app/utils/string';

import ConfirmOrderModal from './Modals/ConfirmOrderModal/ConfirmOrderModal';
import ForceNewOrder from './Modals/ForceNewOrder/ForceNewOrder';

const ALIGN_CENTER = 'center' as AlignType;

type QueryParamsType = {
  sortOrder: APISortOrder;
  sortField: string;
  statusFilter?: OrderType[];
};

const initialQueryParam: QueryParamsType = {
  sortOrder: 'DESC',
  sortField: 'createdAt',
  statusFilter: [ORDER_STATUS.DRAFT, ORDER_STATUS.SENT, ORDER_STATUS.CANCELLED],
};

type Props = {
  forceNewOrderModalOpen: boolean;
  setForceNewOrderModalOpen: (val: boolean) => void;
  submitNewOrder: () => void;
};

const WelcomeKitOrders = ({
  forceNewOrderModalOpen,
  setForceNewOrderModalOpen,
  submitNewOrder,
}: Props) => {
  const history = useHistory();
  const location = useLocation();

  const [orderIdConfirm, setOrderIdConfirm] = useState<number>();

  const queryParams = useMemo(() => {
    const query = new URLSearchParams(location.search);

    return {
      sortField:
        (query.get('sortField') as keyof OrderListItem) ??
        initialQueryParam.sortField,
      sortOrder:
        (query.get('sortOrder') as APISortOrder) ?? initialQueryParam.sortOrder,
      statusFilter: (() => {
        const filter = query.get('statusFilter');

        if (filter === null) {
          return initialQueryParam.statusFilter;
        }

        if (filter === '') {
          return [];
        }

        return parseStringToArray(filter) as OrderType[];
      })(),
    };
  }, [location.search]);

  const createPayload = useCallback(
    () => ({
      sortField: queryParams.sortField ?? initialQueryParam.sortField,
      sortOrder:
        queryParams.sortOrder === '' ? undefined : queryParams.sortOrder,
      status: queryParams.statusFilter ?? initialQueryParam.statusFilter,
    }),
    [queryParams]
  );

  const { data: ordersList, isLoading: isOrderListLoading } = useGetOrderList(
    createPayload()
  );
  const { mutate: confirmOrder } = useConfirmOrder();
  const { downloadOrder } = useDownloadOrder();

  const handleOnChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<OrderListItem> | SorterResult<OrderListItem>[]
  ) => {
    const { field, order } = Array.isArray(sorter)
      ? sorter[0]
      : sorter || { field: undefined, order: undefined };

    if (field)
      updateQueryParams({
        sortOrder: formatSortingForAPI(order ?? 'none'),
        sortField: String(field) ?? '',
      });
  };

  const updateQueryParams = useCallback(
    (params: Record<string, string | undefined>) => {
      const newParams = new URLSearchParams(location.search);

      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value);
        } else if (key === 'statusFilter') {
          newParams.set(key, '');
        } else {
          newParams.delete(key);
        }
      });

      history.push(`${location.pathname}?${newParams.toString()}`);
    },
    [history, location.pathname, location.search]
  );

  const handleStatusFilter = (value: string[]) => {
    updateQueryParams({ statusFilter: value.join(',') ?? undefined });
  };

  const getType = (status: OrderType) => {
    if (status === ORDER_STATUS.SENT) {
      return 'success';
    }

    if (status === ORDER_STATUS.DRAFT) {
      return 'warning';
    }

    return 'default';
  };

  const statusColumnTitle = (
    <ModalCheckBox
      title="Status"
      defaultValue={[
        ORDER_STATUS.DRAFT,
        ORDER_STATUS.SENT,
        ORDER_STATUS.CANCELLED,
      ]}
      onChange={handleStatusFilter}
      items={[
        {
          value: ORDER_STATUS.DRAFT,
          label: parseUppercaseTextToCapitalize(ORDER_STATUS.DRAFT),
        },
        {
          value: ORDER_STATUS.SENT,
          label: parseUppercaseTextToCapitalize(ORDER_STATUS.SENT),
        },
        {
          value: ORDER_STATUS.CANCELLED,
          label: parseUppercaseTextToCapitalize(ORDER_STATUS.CANCELLED),
        },
      ]}
      value={queryParams.statusFilter}
      isCenter={false}
    />
  );

  const handleDownload = async (order: OrderListItem) => {
    try {
      await downloadOrder(order.id, order.confirmationDate);
    } catch (error) {
      alert('Une erreur est survenue lors du téléchargement du fichier.');
    }
  };

  const actionMenu = (order: OrderListItem) => {
    if (order.status === ORDER_STATUS.CANCELLED || order.totalQuantity === 0)
      return <></>;

    return (
      <div className="actions-menu">
        <Dropdown
          dropdownRender={() => (
            <Menu>
              {order.status === ORDER_STATUS.DRAFT ? (
                <Menu.Item
                  key="confirm"
                  onClick={() => setOrderIdConfirm(order.id)}
                >
                  Confirm
                </Menu.Item>
              ) : (
                <Menu.Item key="download" onClick={() => handleDownload(order)}>
                  Download
                </Menu.Item>
              )}
            </Menu>
          )}
          trigger={['click']}
        >
          <EllipsisOutlined rotate={90} />
        </Dropdown>
      </div>
    );
  };

  const columns = [
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      render: (date: string) => renderDate(date, 'dd/MM/yyyy'),
      sorter: true,
    },
    {
      title: 'Confirmation date',
      dataIndex: 'sentAt',
      render: (date: string) => (date ? renderDate(date, 'dd/MM/yyyy') : '-'),
      sorter: true,
    },
    {
      title: 'Total order quantity',
      dataIndex: 'totalQuantity',
    },
    {
      title: statusColumnTitle,
      dataIndex: 'status',
      width: '140px',
      onFilter: (value: string | number | boolean, record: OrderListItem) =>
        record.status === value,
      render: (status: OrderType) => {
        return (
          <TagWithIcon
            text={parseUppercaseTextToCapitalize(status)}
            type={getType(status)}
          />
        );
      },
    },
    {
      dataIndex: 'action',
      render: (_: undefined, value: OrderListItem) => actionMenu(value),
      onCell: () => ({
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
      }),
      align: ALIGN_CENTER,
      width: '45px',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={ordersList}
        onChange={handleOnChange}
        sortDirections={['ascend', 'descend']}
        loading={isOrderListLoading}
        rowKey={'id'}
        pagination={{
          simple: true,
          defaultPageSize: ordersList?.length,
          showSizeChanger: false,
          pageSize: DEFAULT_PAGE_SIZE,
          position: ['bottomRight', 'topRight'],
          total: ordersList?.length || 1,
        }}
        onRow={(order) => {
          return {
            onClick: () =>
              history.push(`/welcome-kit/orders/${order.id}/deliveries`),
          };
        }}
      />
      {forceNewOrderModalOpen && (
        <ForceNewOrder
          isOpen={forceNewOrderModalOpen}
          onClose={() => setForceNewOrderModalOpen(false)}
          submitNewOrder={submitNewOrder}
        />
      )}

      {orderIdConfirm && (
        <ConfirmOrderModal
          isOpen={!!orderIdConfirm}
          onClose={() => setOrderIdConfirm(undefined)}
          onSubmit={() => {
            confirmOrder(orderIdConfirm);
            setOrderIdConfirm(undefined);
          }}
        />
      )}
    </>
  );
};

export default WelcomeKitOrders;
