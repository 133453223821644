import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  PageHeader,
  Pagination,
  Table,
  TablePaginationConfig,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { capitalize } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PublishedCell } from 'app/components/lists/cells';
import { ConfigContext } from 'app/context/ConfigContext/ConfigContext';
import { UserContext } from 'app/context/UserContext/UserContext';
import { usePaginatedPackages } from 'app/hooks/data/packages/usePackages';
import {
  useHidePackage,
  usePublishPackage,
} from 'app/hooks/data/packages/useSavePackage';
import { PackageListItem, PackageStatus } from 'app/typings/packages';
import { dateAndHour } from 'app/utils/dates';
import { useDebounce } from 'app/utils/hooks/useDebounce';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'app/utils/pagination';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
} from 'app/utils/sort';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/layout/page-list.scss';

import ModalCheckBox from '../Extranet/ExtranetBookings/ModalCheckBox';

import './Packages.scss';

const { Content } = Layout;
const { Column } = Table;

export type PackagesListQueryParam = {
  search?: string;
  pageNumber?: number;
  sortOrder?: SortOrder;
  sortingField?: string;
  publishedFilter?: string[];
};

const filterStatus = (val: string) =>
  ['published', 'unpublished', 'hidden'].includes(val) ? val : undefined;

const sortingIntitialState: { sortingField: string; sortOrder: SortOrder } = {
  sortingField: 'updatedAt',
  sortOrder: 'descend',
};

const queryParamInit = {
  pageNumber: 1,
  sortOrder: sortingIntitialState.sortOrder,
  sortingField: sortingIntitialState.sortingField,
  publishedFilter: ['published', 'unpublished', 'hidden'],
} as PackagesListQueryParam;
const queryParamKeys = {
  pageNumber: 'p',
  sortOrder: 'so',
  sortingField: 'sf',
  publishedFilter: 'pu',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  nameSorting: getSortOrder,
  updatedAtSorting: getSortOrder,
  publishedFilter: parseArrayParam(filterStatus),
};

const PackagesList = () => {
  const history = useHistory();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const { user } = useContext(UserContext);
  const [statusFilter, setStatusFilter] = useState<PackageStatus[]>([
    PackageStatus.PUBLISHED,
    PackageStatus.UNPUBLISHED,
    PackageStatus.HIDDEN,
  ]);

  const { mutateAsync: hidePackage } = useHidePackage();
  const { mutateAsync: publishPackage } = usePublishPackage();

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<PackagesListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const pageNumber = queryParam.pageNumber || 1;

  const formatSorting = useCallback(() => {
    if (!queryParam.sortOrder || queryParam.sortOrder === 'none')
      return undefined;

    return `${toSnakeCase(queryParam.sortingField)}:${formatSortingForAPI(
      queryParam.sortOrder
    )}`;
  }, [queryParam.sortOrder, queryParam.sortingField]);

  const debouncedSearch = useDebounce(queryParam.search || '');

  const makePayload = useCallback(
    () => ({
      pageNumber: pageNumber,
      search: debouncedSearch,
      sorting: formatSorting(),
      status: statusFilter || [],
    }),
    [pageNumber, debouncedSearch, formatSorting, statusFilter]
  );

  const { data: packagesList, isLoading } = usePaginatedPackages(makePayload());

  const pagination = packagesList?.pagination;

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const handlePagination = async (pageNumber: number) => {
    scrollToTop();

    updateQueryParam({ pageNumber: pageNumber });
  };

  const handlePublishedStatus = (statuses: Array<PackageStatus>) => {
    updateQueryParam({ publishedFilter: statuses });
    setStatusFilter(statuses);
  };

  const handleOnChange = (
    paginationConfig: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<PackageListItem> | SorterResult<PackageListItem>[]
  ) => {
    const { field, order } = (sorter = Array.isArray(sorter)
      ? sorter[0]
      : sorter || {});

    const currentPage = paginationConfig.current ?? DEFAULT_PAGE;

    if (currentPage !== pagination?.pageNumber) {
      handlePagination(currentPage);
    } else {
      updateQueryParam({
        publishedFilter: (filter.published as string[]) ?? [],
        sortOrder: convertFromAntdSortOrder(order),
        sortingField: field as string,
        pageNumber: 1,
      });
    }
  };

  const handleRowActions = (pkg: PackageListItem) => ({
    onClick: () =>
      history.push({
        pathname: `/packages/${pkg.id}/edit`,
        state: { pathname: location.pathname + location.search },
      }),
  });

  const packagesWithDefaultNames = useMemo(
    () =>
      packagesList?.packages.map((pkg) => ({
        ...pkg,
        name: pkg.aliasForHotel.trim() || `Package #${pkg.id}`,
      })),
    [packagesList]
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      <Pagination
        pageSize={pagination?.pageSize ?? DEFAULT_PAGE_SIZE}
        current={pagination?.pageNumber ?? DEFAULT_PAGE}
        showSizeChanger={false}
        simple={true}
        total={pagination?.itemCount ?? 1}
        onChange={(page) => handlePagination(page)}
      />
    </div>
  );

  const newPackagesButton = (
    <Button
      type="primary"
      size="large"
      onClick={() =>
        history.push({
          pathname: 'packages/new',
          state: { pathname: location.pathname + location.search },
        })
      }
    >
      New
    </Button>
  );

  const actionMenu = (pkg: PackageListItem) => (
    <div className="actions-menu">
      <Dropdown
        dropdownRender={() => (
          <Menu>
            <Menu.Item
              key="preview"
              onClick={() => window.open(`${config?.appUrl}/${pkg.url}`)}
            >
              Preview
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => handleRowActions(pkg).onClick()}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="publish"
              onClick={async () =>
                await publishPackage({
                  packageId: pkg.id,
                  published: !pkg.published,
                })
              }
              disabled={pkg.hidden}
            >
              {pkg.published ? 'Unpublish' : 'Publish'}
            </Menu.Item>
            <Menu.Item
              key="hide"
              onClick={async () =>
                await hidePackage({
                  packageId: pkg.id,
                  hidden: !pkg.hidden,
                })
              }
              disabled={pkg.published || user?.role !== 'superadmin'}
            >
              {pkg.hidden ? 'Show' : 'Hide'}
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  return (
    <Layout className="page-list admin-packages">
      <PageHeader
        className="header"
        title="Packages"
        extra={newPackagesButton}
      />
      <Content className="body">
        <Table<PackageListItem>
          dataSource={packagesWithDefaultNames ?? []}
          pagination={{
            simple: true,
            showSizeChanger: false,
            pageSize: pagination?.pageSize ?? DEFAULT_PAGE_SIZE,
            current: pagination?.pageNumber ?? DEFAULT_PAGE,
            total: pagination?.itemCount || 1,
            position: ['bottomRight'],
          }}
          title={header}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={handleOnChange}
        >
          <Column key="name" title="Alias hotel" dataIndex="name" sorter />
          <Column
            key="aliasForUser"
            title="Alias user"
            dataIndex="aliasForUser"
            sorter
          />
          <Column key="coverLabel" title="Cover label" dataIndex="coverLabel" />
          <Column
            key="hotelName"
            title="Hotel"
            dataIndex="hotelName"
            width={350}
          />
          <Column
            key="updatedAt"
            title="Last modified"
            dataIndex="updatedAt"
            sorter
            render={dateAndHour}
          />
          <Column
            key="published"
            title={
              <ModalCheckBox
                title="Published"
                onChange={handlePublishedStatus}
                items={[
                  {
                    label: capitalize(PackageStatus.PUBLISHED),
                    value: PackageStatus.PUBLISHED,
                  },
                  {
                    label: capitalize(PackageStatus.UNPUBLISHED),
                    value: PackageStatus.UNPUBLISHED,
                  },
                  {
                    label: capitalize(PackageStatus.HIDDEN),
                    value: PackageStatus.HIDDEN,
                  },
                ]}
                isCenter={false}
                value={statusFilter}
              />
            }
            dataIndex="published"
            render={PublishedCell}
          />

          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
      </Content>
    </Layout>
  );
};

export default PackagesList;
